'use client'

import { Text } from '@/components/ui/text'
import HeroCenteredParallaxVideos from './hero-centered-parallax.videos'
import {
  useHeroCenteredParallax,
  useHeroCenteredParallaxProps
} from './use-hero-centered-parallax'
import { Button, ButtonGroup } from '@/components/ui/button'
import { ArtDirection } from '@/components/ui/art-direction'

export type HeroCenteredParallaxProps = useHeroCenteredParallaxProps

const HeroCenteredParallax = (props: HeroCenteredParallaxProps) => {
  const {
    className,
    sectionName,
    topVideos,
    bottomVideos,
    isLoggedIn,
    data: {
      theme,
      preTitle,
      title,
      description,
      titleButtons,
      buttons,
      titleCta,
      cta,
      backgroundMobile,
      moisesStudio
    },
    styles
  } = useHeroCenteredParallax(props)
  
  return (
    <section
      data-component-name={sectionName}
      className={styles.base({ className })}
    >
      <div className={styles.wrapper()}>
        <div className={styles.caption()}>
          {!!preTitle && (
            <Text
              className={styles.preTitle()}
              theme={theme}
              weight="semibold"
              size="tag"
              uppercase
            >
              {preTitle}
            </Text>
          )}
          {!!title && (
            <Text
              theme={theme}
              tag="h1"
              size="h2"
              weight="medium"
              className={styles.title()}
            >
              {title}
            </Text>
          )}
          {!!description && (
            <Text
              theme={theme}
              size="subtitle"
              weight="medium"
              className={styles.description()}
            >
              {description}
            </Text>
          )}
          {isLoggedIn && moisesStudio ? (
            <Button 
              title={moisesStudio.text}
              href={moisesStudio.url}
              appearance="solid"
              color="acqua"
              className={styles.buttonGroup()}
            >
              {moisesStudio.text}
            </Button>
          ) : (
            <ButtonGroup
              theme={theme}
              sectionName={sectionName}
              title={titleButtons}
              buttons={buttons}
              className={styles.buttonGroup()}
              align="left"
              hideOnMobile
            />
          )}
          <ButtonGroup
            theme={theme}
            sectionName={sectionName}
            title={titleCta}
            buttons={cta}
            className={styles.buttonGroup()}
            align="left"
            hideStoresOnDesktop={false}
          />
        </div>
        <HeroCenteredParallaxVideos
          topVideos={topVideos}
          bottomVideos={bottomVideos}
        />
      </div>
      <ArtDirection
        priority
        hiddenOn="md"
        containerClassName={styles.image()}
        sizes="100vw"
        mobile={{
          src: backgroundMobile?.url,
          alt: backgroundMobile?.alternativeText,
          width: backgroundMobile?.width,
          height: backgroundMobile?.height
        }}
      />
    </section>
  )
}

export default HeroCenteredParallax
