'use client'

import { BlockProps, HeroCenteredParallax } from '@/types'
import { heroCenteredParallax } from './hero-centered-parallax.variants'
import { useAuth } from '@/hooks/use-auth'

export type useHeroCenteredParallaxProps = BlockProps<HeroCenteredParallax>

export const useHeroCenteredParallax = (
  props: useHeroCenteredParallaxProps
) => {
  const {
    className = '',
    sectionName = '',
    data: { theme = 'dark', videos, ...restData }
  } = props

  const styles = heroCenteredParallax({ theme })
  const { isLoggedIn } = useAuth()

  const videosPerRow = Math.ceil(videos.length / 2)
  const topVideos = videos.slice(0, videosPerRow)
  const bottomVideos = videos.slice(videosPerRow)

  return {
    className,
    sectionName,
    topVideos,
    bottomVideos,
    isLoggedIn,
    data: {
      theme,
      ...restData
    },
    styles
  }
}
